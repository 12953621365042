import type {Poster} from '@PosterWhiteboard/poster/poster.class';

export enum GlobalPosterEditorJqueryElement {
  WHITEBOARD_CONTAINER = 'WHITEBOARD_CONTAINER',
  POSTER_EDITOR_MOBILE_BOTTOM_EDITING_BAR = 'POSTER_EDITOR_MOBILE_BOTTOM_EDITING_BAR',
  POSTER_EDITOR_NAVBAR = 'POSTER_EDITOR_NAVBAR',
  WHITEBOARD = 'WHITEBOARD',
  POST_POSTER_ELEMENT = 'POST_POSTER_ELEMENT',
  POSTER_HORIZONTAL_SCROLL = 'POSTER_HORIZONTAL_SCROLL',
  POSTER_VERTICAL_SCROLL = 'POSTER_VERTICAL_SCROLL',
  POSTER_EDITOR_WEB_BOTTOM_BAR = 'POSTER_EDITOR_WEB_BOTTOM_BAR',
}

export enum ElementToHide {
  DOWNLOAD = 'download',
  FILE = 'file',
  UPGRADE = 'upgrade',
}

export interface PosterEditor {
  whiteboard?: Poster;
  config?: PosterEditorConfig;
  elements: Partial<Record<GlobalPosterEditorJqueryElement, JQuery>>;
}

export interface PosterEditorConfig {
  imageDownlaodCostInCredits: number;
  videoDownloadCostInCredits: number;
  gettyFormattedDownloadCostForNonPremiumUsers: string;
  isSpellCheckEnabled: boolean;
  hasPublishedUsingDigitalDisplay: boolean;
  elementsToHide?: Array<ElementToHide>;
  nextBtnCaption?: string;
  showSuperUserOptions?: boolean;
  idOrder?: string;
  showOnBoardingPopups?: boolean;
  showMediaOnboardingPopup?: boolean;
  showMobileUXOnBoarding?: boolean;
  isUnifiedCredits?: boolean;
  businessProfileUrl?: string;
}

export const EDITOR_SMALL_SCREEN_WIDTH_THRESHOLD = 780;
export const EDITOR_COLLAPSABLE_SIDEBAR_THRESHOLD = 900;
export const DESIGN_EDITOR_ONBOARDING_COOKIE = 'pmwne_onboarding';
export const ENABLE_PAGES = false;
